import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../components/css/careers.css'
import Career from 'images/careers/career_banner.png'
import Accounting from 'images/careers/accounting.svg'
import Admin from 'images/careers/admin.svg'
import Marketing from 'images/careers/marketing.svg'
import Operations from 'images/careers/operations.svg'
import Sales from 'images/careers/sales.svg'
import Tech from 'images/careers/tech.svg'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll'
import Seo from '../components/Seo'
import careersList from '../data/static/CareersListData'
import { CAREER_SPIELS } from '../data/spiels/careers'

const Careers = () => {
  return (
    <div>
      <Seo
        title="Digipay PH | Careers"
        description="Digipay is a digital payments and financial services platform with
  a mobile wallet, an industry leading biller ecosystem,
  and an interoperable backend."
        keywords="digipay.ph/careers/, Work, Careers, Digipay Careers, Work at Digipay, careers@digipay.ph, Admin, Tech, Work with us"
      />
      <Header />

      <img className="career-banner" src={Career} alt="career-image" />
      <div className="career-section">

        <h5 className="text-center">
          {CAREER_SPIELS.CAREER_HEADER}
        </h5>

        <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
          <h5 id="resume">
            <strong>{CAREER_SPIELS.SEND_RESUME}</strong>
            <span>
              <a
                href={`mailto: ${CAREER_SPIELS.EMAIL}`}
                id="resume-email"
                onClick={() => window.open('mailto: careers@digipay.ph')}
              >
                {CAREER_SPIELS.EMAIL}
              </a>
            </span>
          </h5>
        </ScrollAnimation>

      </div>

      <Footer />
    </div>
  )
}

export default Careers
